const $http = 'https://www.offshorecloud.org.cn/'
const Url = {
    // 查询
    list:$http+'codia/chargingStandard/list',
    //新增
    add:$http+'codia/chargingStandard/add',
    // 修改
    update:$http+'codia/chargingStandard/update',
    // 删除
    delete:$http+'codia/chargingStandard/delete',
    // 获取所有费用类型
    getCostList:$http+'codia/chargingStandard/getCostList',
    // 获取公司名列表
    getCompanyList:$http+'codia/company/getCompanyList',

// 财务报表模块
    // 获取财务报表
    getFinancialList :$http+'codia/financial/getFinancialStatementsList',
    // 财务报表详情
    getFinancialDetail : $http+'codia/financial/getFinancialDetail',
    // 财务报表新增
    // addFinancialStatement:$http+'codia/financial/addFinancialStatement',
    // 删除财务列表项
    deleteFinancialStatement:$http+'codia/financial/deleteFinancialStatement',
    // 编辑财务报表
    updateDetail:$http+'codia/financial/updateDetail',

// 发票列表
    // 获取发票列表
    getInvoiceList: $http+"codia/invoice/getInvoiceList",
    // 获取发票详情
    getInvoiceById: $http+"codia/invoice/getInvoiceById",
    // 新增发票
    addInvoice:$http+"codia/invoice/addInvoice",
    // 修改发票信息
    updateInvoice:$http+"codia/invoice/updateInvoice",
    // 删除发票
    deletedInvoice:$http+"codia/invoice/deletedInvoice",
    // 获取发票上传路径
    invoiceFileUpload:$http+"codia/file/invoiceFileUpload",
}
export default Url