<template>
  <div id="app">
    <router-view/>
  </div> 
</template>
<script>
	import {mapMutations} from 'vuex'
	export default {
		methods:{
			...mapMutations({
				setUserData:'setUserData',
			}),
		}, 
		mounted() {
			let userData = localStorage.getItem('codiaLoginData');
			let sessionUserData = sessionStorage.getItem('sessionUserData');
			if(userData){	//下次继续登录
				this.setUserData(JSON.parse(userData));
			}else if(sessionUserData){	//刷新页面
				this.setUserData(JSON.parse(sessionUserData));
			} 
		},   
		created() {
			let baseContentHeight = 1340;
			let rightLoginWidth = 60;
			if(document.body.clientWidth < 1340 + rightLoginWidth * 2 + 60){
				baseContentHeight = document.body.clientWidth - rightLoginWidth * 2 - 60;
			}
			document.getElementsByTagName('body')[0].style.setProperty('--height-baseContent', baseContentHeight+'px');
			document.getElementsByTagName('body')[0].style.setProperty('--height-rightLoginWidth', rightLoginWidth+'px');
			
		}
	}
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
	width: 100%;
	min-height: 100%;
	font-size: $base-font-size;
	color: $cont-font-color;
}
</style>
