export default {
	namespaced:true,
	state:{
		homePageData:{},//首页数据
		cockpitData:{},//驾驶舱页数据 包含坐标信息

	}, 
	mutations:{
		setHomePageData(state, obj){
			state.homePageData = obj;
		},
		setCockpitData(state, obj){
			state.cockpitData = obj;
		},
		
	},
	actions:{
		getHomePageData({rootGetters,commit,rootState}){	//获取首页数据
			let obj = {};
			obj.language = rootState.language;
			obj.userToken = rootState.userToken;
			rootGetters.post(`${rootState.baseUrl}/data/getHomePageData`,obj).then(res =>{
				commit('setHomePageData',res);
			})
		},
		getCockpitData({rootGetters,commit,rootState}){	//获取驾驶舱数据
			let obj = {};
			obj.language = rootState.language;
			obj.userToken = rootState.userToken;
			rootGetters.post(`${rootState.baseUrl}/data/getCockpitData`,obj).then(res =>{
				commit('setCockpitData',res);
			})
		},
	}
}