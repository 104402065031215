import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

//未登录时
const IndexPage = () => import( /* webpackChunkName: "unLoginPages" */ '../views/unLoginPages/indexPage/indexPage.vue')
const EquipmentQuery = () => import( /* webpackChunkName: "unLoginPages" */ '../views/unLoginPages/equipmentQuery/equipmentQuery.vue')

const CreatedQRcode = () => import( /* webpackChunkName: "unLoginPages" */ '../views/unLoginPages/equipmentQRcode/createdQRcode.vue')
const AboutUs = () => import( /* webpackChunkName: "unLoginPages" */ '../views/unLoginPages/aboutUs/aboutUs.vue')
const AboutLogin = () => import( /* webpackChunkName: "unLoginPages" */ '../views/unLoginPages/aboutLogin/aboutLogin.vue')
const Login = () => import( /* webpackChunkName: "unLoginPages" */ '../views/unLoginPages/aboutLogin/login.vue')
const RegisterUser = () => import( /* webpackChunkName: "unLoginPages" */ '../views/unLoginPages/aboutLogin/register.vue')
const ForgrtPwd = () => import( /* webpackChunkName: "unLoginPages" */ '../views/unLoginPages/aboutLogin/forgrtPwd.vue')

const OpenInterface = () => import( /* webpackChunkName: "unLoginPages" */ '../views/unLoginPages/openInterface/openInterface.vue')


//登录后
const HavenLoginHome = () => import(/* webpackChunkName: "havenLoginPages" */ '../views/pages/havenLoginHome.vue')
const HomePage = () => import(/* webpackChunkName: "havenLoginPages" */ '../views/pages/homePage/homePage.vue')
//驾驶舱
const Cockpit = () => import(/* webpackChunkName: "havenLoginPages" */ '../views/pages/cockpit/cockpit.vue')
//海工装备资料
const EquipmentData = () => import(/* webpackChunkName: "havenLoginPages" */ '../views/pages/equipmentData/equipmentData.vue')
//操作记录查询
const RecordQuery = () => import(/* webpackChunkName: "havenLoginPages" */ '../views/pages/recordQuery/recordQuery.vue')
//模板管理
const TempManage = () => import(/* webpackChunkName: "havenLoginPages" */ '../views/pages/tempManage/tempManage.vue')
//财务管理 --- 收费标准
const ChargingStandard = () => import(/* webpackChunkName: "havenLoginPages" */ '../views/pages/financialManage/chargingStandard.vue')
//财务管理 --- 财务报表
const ReportForm = () => import(/* webpackChunkName: "havenLoginPages" */ '../views/pages/financialManage/reportForm.vue')
//财务管理 --- 发票管理
const Invoice = () => import(/* webpackChunkName: "havenLoginPages" */ '../views/pages/financialManage/invoice.vue')
//系统设置 --- 个人设置
const UserSettings = () => import(/* webpackChunkName: "havenLoginPages" */ '../views/pages/systemManage/userSettings.vue')
//系统设置 --- 用户管理
const UserManage = () => import(/* webpackChunkName: "havenLoginPages" */ '../views/pages/systemManage/userManage.vue')
//系统设置 --- 企业入网申请
const NetworkAccessApply = () => import(/* webpackChunkName: "havenLoginPages" */ '../views/pages/systemManage/networkAccessApply.vue')
//
//员工管理
const StaffManage = () => import(/* webpackChunkName: "havenLoginPages" */ '../views/pages/staffManage/staffManage.vue')
//领用记录
const GetLog = () => import(/* webpackChunkName: "havenLoginPages" */ '../views/pages/staffManage/getLog.vue')


const routes = [{
		path: '/',
		name: 'Home',
		component: Home,
		children: [{
				path: '/indexPage',
				name: 'indexPage',
				component: IndexPage
			},
			{
				path: '/equipmentQuery',
				name: 'equipmentQuery',
				component: EquipmentQuery
			},
			// {
			// 	path: '/equipmentQuery/:val',
			// 	name: 'equipmentQuery',
			// 	component: EquipmentQuery
			// },
			
			{
				path: '/CreatedQRcode',
				name: 'CreatedQRcode',
				component: CreatedQRcode
			},
			{
				path: '/aboutUs',
				name: 'aboutUs',
				component: AboutUs
			},
			{
				path: '/openInterface',
				name: 'openInterface',
				component: OpenInterface
			}
		],
	},
	{
		path: '/aboutLogin',
		name: 'aboutLogin',
		component: AboutLogin,
		children: [{
				path: '/login',
				name: 'login',
				component: Login,
			},
			{
				path: '/registerUser',
				name: 'registerUser',
				component: RegisterUser,
			},
			{
				path: '/forgrtPwd',
				name: 'forgrtPwd',
				component: ForgrtPwd,
			}
		]
	},
	{
		path: '/havenLoginHome',
		name: 'havenLoginHome',
		component: HavenLoginHome,
		children:[{
			path: '/homePage',
			name: 'homePage',
			component: HomePage,
		},{
			path: '/cockpit',
			name: 'cockpit',
			component: Cockpit,
		},{
			path: '/equipmentData',
			name: 'equipmentData',
			component: EquipmentData,
		},{
			path: '/recordQuery',
			name: 'recordQuery',
			component: RecordQuery,
		},{
			path: '/tempManage',
			name: 'tempManage',
			component: TempManage,
		},{
			path: '/chargingStandard',
			name: 'chargingStandard',
			component: ChargingStandard,
		},{
			path: '/reportForm',
			name: 'reportForm',
			component: ReportForm,
		},{
			path: '/invoice',
			name: 'invoice',
			component: Invoice,
		},{
			path: '/userSettings',
			name: 'userSettings',
			component: UserSettings,
		},{
			path: '/userManage',
			name: 'userManage',
			component: UserManage,
		},{
			path: '/networkAccessApply',
			name: 'networkAccessApply',
			component: NetworkAccessApply,
		},
		{
			path: '/staffManage',
			name: 'staffManage',
			component: StaffManage,
		},
		{
			path: '/getLog',
			name: 'getLog',
			component: GetLog,
		},
	]
	} 
]
// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const router = new VueRouter({
	mode: 'hash',
	base: process.env.BASE_URL,
	routes
})

export default router