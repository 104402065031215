// import { resolve } from "core-js/fn/promise";

export default {
	namespaced:true,
	state:{
		count:0,
		applyEnterprise:{},	//入网企业信息
		applyResult:{},	//提交入网申请结果
		examineCompanyResult:{},	//审核入网申请结果
		userList:{},
		deleteCompanyResult:{},
		accountState:{},//是否禁用
		updateUserInfo:{},//修改账号信息
		loading:false,
	}, 
	mutations:{
		SetApplyResult(state, obj){
			state.applyResult = obj;
		},
		SetExamineCompanyResult(state, obj){
			state.examineCompanyResult = obj;
		},
		setApplyEnterprise(state, obj){
			state.applyEnterprise = obj;
		},
		SetUserList(state, obj){
			state.userList = obj;
		},
		SetDeleteCompanyResult(state, obj){
			state.deleteCompanyResult = obj;
		},
		SetAccountState(state, obj){
			state.accountState = obj;
		},
		SetUpdateUserInfo(state, obj){
			state.updateUserInfo = obj;
		},
		changeLoad(state,obj){
			state.loading = obj
		}
	},
	actions:{
		getApplyEnterprise({rootGetters,commit,rootState}){	//查询入网企业信息
			let obj = {
				userToken:rootState.userToken,
			}
			rootGetters.post(`${rootState.baseUrl}/company/companyInfo`,obj).then(res =>{
				commit('setApplyEnterprise',res);
				if(res.success && res.data){
					commit('setEnterpriseStatus',res.data.userStatus,{root:true});
				}
			})
		},
		apply({rootGetters,commit,rootState},obj){	//提交入网申请
			obj.language = rootState.language;
			obj.userToken = rootState.userToken;
			rootGetters.post(`${rootState.baseUrl}/company/enterpriseApplication`,obj).then(res =>{
				commit('SetApplyResult',res);
			})
		},
		examineCompany({rootGetters,commit,rootState},obj){	//审核入网申请
			obj.language = rootState.language;
			obj.userToken = rootState.userToken;
			rootGetters.post(`${rootState.baseUrl}/company/examineCompany`,obj).then(res =>{
				commit('SetExamineCompanyResult',res);
			})
		},
		getList({rootGetters,commit,rootState},obj){	//获取用户列表
			obj.language = rootState.language;
			obj.userToken = rootState.userToken;
			rootGetters.post(`${rootState.baseUrl}/company/list`,obj).then(res =>{
				commit('SetUserList',res);
			})
		},
		deleteCompany({rootGetters,commit,rootState},obj){	//删除
			obj.language = rootState.language;
			obj.userToken = rootState.userToken;
			rootGetters.post(`${rootState.baseUrl}/company/deleteCompany`,obj).then(res =>{
				commit('SetDeleteCompanyResult',res);
			})
		},
		exportCompanyList({rootGetters,commit,rootState},obj){	//导出用户列表
			obj.language = rootState.language;
			obj.userToken = rootState.userToken;
			let url = `${rootState.baseUrl}/company/exportCompanyList?searchParam=${obj.searchParam}&token=${rootState.userToken}&language=${rootState.language}`;
			// console.log(url);
			window.open(url);
		},
		
		getAccountState({rootGetters,commit,rootState},obj){	//禁用
			obj.language = rootState.language;
			obj.userToken = rootState.userToken;
			rootGetters.post(`${rootState.baseUrl}/company/changeAccountState`,obj).then(res =>{
				commit('SetAccountState',res);
			})
		},
		getUpdateUserInfo({rootGetters,commit,rootState},obj){	//修改用户信息
			obj.language = rootState.language;
			obj.userToken = rootState.userToken;
			// let obj = {
			// 	userToken:rootState.userToken,
			// }
			rootGetters.post(`${rootState.baseUrl}/company/changeUserInfo`,obj).then(res =>{
				commit('SetUpdateUserInfo',res);
			})
		},
		getChangeLoader({rootGetters,commit,rootState},obj){	//禁用
			commit('changeLoad', true)
			obj.language = rootState.language;
			obj.userToken = rootState.userToken;			
			return new Promise((resolve,reject)=>{
				rootGetters.post(`${rootState.baseUrl}/company/regNode`,obj).then(res =>{
					console.log(res);
					if (res.errorCode == '200') {
						resolve(res.errorCode)
					}else{
						reject(res.message)
					}
					commit('changeLoad', false)
				})
			})
			
		},
	}
}