export default {
	namespaced:true,
	state:{
		count:0,
		list:{},
		loading:false
	}, 
	mutations:{
		SetList(state, obj){
			state.list = obj;
			state.loading = false
		},
	},
	actions:{
		getList({rootGetters,commit,rootState},obj){	//获取列表
			obj.language = rootState.language;
			obj.userToken = rootState.userToken;
            // console.log(`${rootState.baseUrl}/identity/getIdentityList`);
			rootGetters.post(`${rootState.baseUrl}/identity/getIdentityList`,obj).then(res =>{
				commit('SetList',res);
				// console.log('海工装备资料');
				// console.log(res);
			}) 
		},
	}
}