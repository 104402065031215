import CryptoJs from 'crypto-js'
const key = CryptoJs.enc.Utf8.parse("cimcitech_123456");
const iv = CryptoJs.enc.Utf8.parse("cimcitech_123456");
export default {
 	/**
 	 * 加密
 	 */
	encrypt(data) {
		let encrypted = CryptoJs.AES.encrypt(data, key, {
			iv: iv,
			mode: CryptoJs.mode.CBC,
			padding: CryptoJs.pad.Pkcs7
		});
		return encrypted.toString();
	},
 	/**
 	 * 解密
 	 */
 	decrypt(data) {
		let decrypted = CryptoJs.AES.decrypt(data, key, {
			iv: iv,
			mode: CryptoJs.mode.CBC,
			padding: CryptoJs.pad.Pkcs7
		});
		return decrypted.toString(CryptoJs.enc.Utf8);
 	}

}
