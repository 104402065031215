<!--  -->
<template>
  <div class="tabCut">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="注册和key的申请" name="first">111</el-tab-pane>
      <el-tab-pane label="获取装备二维码数据" name="second">222</el-tab-pane>
      <el-tab-pane label="维保数据推送" name="third">333</el-tab-pane>
      <el-tab-pane label="自定义接口" name="fourth">444</el-tab-pane>
      <el-tab-pane label="第三方系统主动推送" name="fifth">555</el-tab-pane>
      <el-tab-pane label="下载说明书" name="sixth">666</el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      activeName: "first",
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    handleClick(tab, event) {
      // console.log(tab, event);
      
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
.tabCut {
  padding: 20px 15%;
}
</style>