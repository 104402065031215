export default {
	namespaced:true,
	state:{
		logList:{},//操作记录
        
	}, 
	mutations:{
		SetLogList(state, obj){
			state.logList = obj;
		},
	},
	actions:{
		getLogList({rootGetters,commit,rootState},obj){	//获取操作记录列表
			obj.language = rootState.language;
			obj.userToken = rootState.userToken;
			rootGetters.post(`${rootState.baseUrl}/logAccount/getLogAccountList`,obj).then(res =>{
				commit('SetLogList',res);
				// console.log(res);
			})
		},
		
	}
}