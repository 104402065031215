export default {
	namespaced:true,
	state:{
		count:0,
		registerSMSCodeResult:{},
		loginSMSCodeResult:{},
		forgetPwdSMSCodeResult:{},
		registerResult:{},
		rememberPassword:true,	//是否记住密码
		accountLoginResult:{},
		forgetLoginResult:{},
		resetPasswordResult:{},
	}, 
	mutations:{
		setRememberPassword(state, val){
			state.rememberPassword = val;
		},
		setRegisterSMSCodeResult(state, obj){
			state.registerSMSCodeResult = obj;
		},
		setLoginSMSCodeResult(state, obj){
			state.loginSMSCodeResult = obj;
		},
		setForgetPwdSMSCodeResult(state, obj){
			state.forgetPwdSMSCodeResult = obj;
		},
		setRegisterResult(state, obj){
			state.registerResult = obj;
		},
		setAccountLoginResult(state, obj){
			state.accountLoginResult = obj;
		},
		setforgetLoginResult(state, obj){
			state.forgetLoginResult = obj;
		},
		setResetPasswordResult(state, obj){
			state.resetPasswordResult = obj;
		},
	},
	actions:{
		registerSMSCode({rootGetters,commit,rootState},obj){	//获取注册验证码
			obj.language = rootState.language;
			rootGetters.post(`${rootState.baseUrl}/company/registerEmailCode`,obj).then(res =>{
				commit('setRegisterSMSCodeResult',res);
			})
			// rootGetters.post(`${rootState.baseUrl}/company/registerSMSCode`,obj).then(res =>{
			// 	commit('setRegisterSMSCodeResult',res);
			// })
		},
		loginSMSCode({rootGetters,commit,rootState},obj){	//获取登录验证码
			obj.language = rootState.language;
			rootGetters.post(`${rootState.baseUrl}/company/loginSMSCode`,obj).then(res =>{
				commit('setLoginSMSCodeResult',res);
			})
		},
		register({rootGetters,commit,rootState},obj){	//注册
			obj.language = rootState.language;
			rootGetters.post(`${rootState.baseUrl}/company/register`,obj).then(res =>{
				commit('setRegisterResult',res);
			})
		},
		accountLogin({rootGetters,commit,rootState,state},obj){	//用户名登录
			rootGetters.post(`${rootState.baseUrl}/company/webAccountLogin`,obj).then(res =>{
				
				if(res.success){	//登录成功
					if(state.rememberPassword){	//如果下次直接登录
						localStorage.setItem('codiaLoginData',JSON.stringify(res.data));
					}else{
						localStorage.removeItem('codiaLoginData');
					}
					commit('setUserData',res.data,{root:true});
					sessionStorage.setItem('sessionUserData',JSON.stringify(res.data))
				}
				commit('setAccountLoginResult',res);
			})
		},
		phoneLogin({rootGetters,commit,rootState,state},obj){	//短信登录
			obj.language = rootState.language;
			rootGetters.post(`${rootState.baseUrl}/company/phoneLogin`,obj).then(res =>{
				if(res.success){	//登录成功
					if(state.rememberPassword){	//如果下次直接登录
						localStorage.setItem('codiaLoginData',JSON.stringify(res.data));
					}else{
						localStorage.removeItem('codiaLoginData');
						sessionStorage.setItem('sessionUserData',JSON.stringify(res.data))
					}
					commit('setUserData',res.data,{root:true});
				}
				commit('setAccountLoginResult',res);
			})
		},
		forgetPwdSMSCode({rootGetters,commit,rootState},obj){	//忘记密码 验证码
			obj.language = rootState.language;
			// 10.43.10.106:3000/codia/company/loginEmailCode
			rootGetters.post(`${rootState.baseUrl}/company/loginEmailCode`,obj).then(res =>{
				commit('setForgetPwdSMSCodeResult',res);
			})
		},
		forgetPhoneLogin({rootGetters,commit,rootState,state},obj){	//忘记密码 登录接口获取token
			obj.language = rootState.language;
			rootGetters.post(`${rootState.baseUrl}/company/emailLogin`,obj).then(res =>{
				commit('setforgetLoginResult',res);
			})
		},
		// register({rootGetters,commit,rootState},obj){	//注册
		// 	obj.language = rootState.language;
		// 	rootGetters.post(`${rootState.baseUrl}/company/register`,obj).then(res =>{
		// 		commit('setRegisterResult',res);
		// 	})
		// },
		resetPassword({rootGetters,commit,rootState},obj){	//修改密码
			obj.language = rootState.language;
			rootGetters.post(`${rootState.baseUrl}/company/resetPassword`,obj).then(res =>{
				commit('setResetPasswordResult',res);
			})
		},
		
	}
}