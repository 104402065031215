import Url from "@/axios/url.js";
export default{
    namespaced:true,

    state:{
        dialogVisible:false, //显示详情列表
        mold:'',
        invoiceTableData:[],
        invoiceDetail:[],
        title:'',
        companyList:[], //公司名
        loading:true,
    },

    mutations:{
        changeShow(state,res){
            state.dialogVisible = res
        },
        changeMold(state,res){
            state.mold = res
        },
        changeInvoiceTable(state,obj){
            state.invoiceTableData = obj
        },
        changeInvoiceDetail(state,obj){
            state.invoiceDetail = obj
        },
        changeTitle(state,obj){
            state.title = obj
        },
        changeCompanyList(state,res){
            state.companyList = res
        },
        changeLoad(state,obj){
			state.loading = obj
		}
    },
    actions:{
        getFinancialList({ rootGetters, commit, rootState , }, obj) {	// 获取发票列表
            obj.userToken = rootState.userToken
            commit('changeLoad', true);
            rootGetters.post(Url.getInvoiceList, obj).then(res => {
                commit('changeLoad', false);
                commit('changeInvoiceTable', res.data);
            })
        },
        getFinancialDetail({ rootGetters, commit, rootState }, obj) {	// 获取发票列表详情
            obj.userToken = rootState.userToken
            rootGetters.post(Url.getInvoiceById, obj).then(res => {
                commit('changeInvoiceDetail', res.data);
            })
        },
        addFinancialList({ rootGetters, commit, rootState}, obj) {	// 添加发票列表
            return new Promise(resolve=>{
                obj.userToken = rootState.userToken
                rootGetters.post(Url.addInvoice, obj).then(res => {
                    resolve (res)
                })
            })
            
        },
        updateFinancialList({ rootGetters, commit, rootState }, obj) {	// 编辑发票
            return new Promise(resolve=>{
                obj.userToken = rootState.userToken
                rootGetters.post(Url.updateInvoice, obj).then(res => {
                    // commit('changeInvoiceDetail', res.data);
                    resolve(res);
                })
            })
            
        },
        deleteFinancialList({ rootGetters, commit, rootState }, obj) {	// 删除发票
            return new Promise(resolve=>{
                obj.userToken = rootState.userToken
                rootGetters.post(Url.deletedInvoice, obj).then(res => {
                    // commit('changeInvoiceDetail', res.data);
                    resolve(res);
                })
            })
            
        },
        getCompanyList({ rootGetters, commit, rootState }, obj) {	// 获取公司名列表
            obj.userToken = rootState.userToken;
            rootGetters.post(Url.getCompanyList, obj).then(res => {
                commit('changeCompanyList', res.data);
            })
        },
    }
}