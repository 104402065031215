<template>
	<div class="header_page_box">
		<div class="header_cont_box">
			<img src="@/assets/unLoginPages/components/logo_index.png" />
			<div class="center_box">
				<el-menu
					:default-active="activeIndex"
					class="el-menu-demo"
					mode="horizontal"
					@select="handleSelect"
					background-color="#00508F"
					text-color="#fff"
					active-text-color="#6DB1EA"
				>
					<el-menu-item index="/IndexPage">首页</el-menu-item>
					<el-menu-item index="/equipmentQuery">海工装备查询</el-menu-item>
					<el-menu-item index="/CreatedQRcode">海工设备二维码生成</el-menu-item>
					<el-menu-item index="/aboutUs">关于我们</el-menu-item>
				</el-menu>
			</div>
			<div class="phone_box">
				<span class="phone_box_icon"><i class="el-icon-phone"></i></span>
				021-58285511-866
			</div>
			<div class="right_abs_box">
				<div class="login_btn" @click="navigatorToLogin">登录</div>
				<!-- <el-dropdown  @command="command">
					<div class="user_icon"><i class="el-icon-user-solid"></i></div>
					<el-dropdown-menu slot="dropdown">
						<el-dropdown-item command="navigatorToLogin">登录</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown> -->
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
export default {
	data() {
		return {
			
		};
	},
	computed: {
		...mapState({
			isLogin: state => state.isLogin,
			activeIndex: state => state.activeIndex,
		})
	},
	methods: {
		...mapMutations({
			setActiveIndex:'setActiveIndex',
		}),
		handleSelect(key, keyPath) {
			this.setActiveIndex(key);
			this.$router.replace(key);
		},
		command(e){
			this[e]();
		},
		navigatorToLogin(){
			this.$router.push('/login');
		},
	},
	mounted() {
	function change() {
      var Root = document.documentElement;
      Root.style.fontSize = window.innerWidth / 192 + 'px';
    //   console.log(Root.style.fontSize);
    };
    change();
    window.onresize = function () {

      console.log('浏览器窗口的大小被改变了');
      change();
    }
    // console.log(window.innerWidth);
    // document.documentElement.style.fontSize=10+'px'
    // console.log(document.documentElement.style.fontSize);
	}
};
</script>

<style lang="scss" scoped>
.header_page_box {
	width: 100%;
	height: 100%;
	position: relative;
	background-color: $theam-bgcolor;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	.header_cont_box {
		width: $heightBaseContent;
		height: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		position: relative;
		.phone_box {
			width: auto;
			height: 100%;
			display: flex;
			align-items: center;
			.phone_box_icon {
				font-size: 28px;
				margin-right: 10px;
			}
		}
		.right_abs_box {
			width: $rightLoginWidth;
			height: 100%;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: flex-end;
			position: absolute;
			top: 0;
			right: calc(0% - #{$rightLoginWidth});
			.user_icon {
				cursor: pointer;
				color: #fff;
				font-size: 28px;
			}
			.to_login {
				cursor: pointer;
			}
			.login_btn{
				cursor: pointer;
				box-sizing: border-box;
				padding-bottom: 4px;
				font-weight: bold;
			}
		}
	}
}
</style>


<style scoped>
.el-menu-item {
  font-size: 1.6rem !important;
}
.el-menu-item {
  padding:0 2rem !important;
}
</style>
