import { render, staticRenderFns } from "./footer.vue?vue&type=template&id=2f1a52b3&scoped=true&"
import script from "./footer.vue?vue&type=script&lang=js&"
export * from "./footer.vue?vue&type=script&lang=js&"
import style0 from "./footer.vue?vue&type=style&index=0&id=2f1a52b3&lang=scss&scoped=true&"
import style1 from "./footer.vue?vue&type=style&index=1&id=2f1a52b3&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.9.8@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f1a52b3",
  null
  
)

export default component.exports