import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/store.js'

import echarts from 'echarts'
Vue.prototype.$echarts = echarts

Vue.config.productionTip = false

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import aes from './common/js/cryptoAES.js'
Vue.prototype.$aesEncrypt = aes.encrypt;

import Tab from './views/unLoginPages/components/tab.vue'
Vue.component('Tab', Tab);

//前端生成excel导出
const { export_json_to_excel } = require('./common/lib/Export2Excel.js');//注意这个Export2Excel路径
Vue.prototype.export_json_to_excel = export_json_to_excel;

import commonApi from './common/js/API.js'
Vue.prototype.$commonApi = commonApi;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
