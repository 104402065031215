export default {
    //员工管理
	namespaced:true,
	state:{
		count:0,
		list:{},//列表数据
		addStaff:{},//添加员工
		delStaff:{},
		updateStaff:{},
		logList:{},//领用记录
		unbind:{},//解绑

	}, 
	mutations:{
		SetList(state, obj){
			state.list = obj;
		},
		SetAddStaff(state, obj){
			state.addStaff = obj;
		},
		SetDelStaff(state, obj){
			state.delStaff = obj;
		},
		SetUpdateStaff(state, obj){
			state.updateStaff = obj;
		},
		//资产领用记录
		setLogList(state, obj){
			state.logList = obj;
		},
		//解绑
		setUnbind(state, obj){
			state.unbind = obj;
		},

	},
	actions:{
		getList({rootGetters,commit,rootState},obj){	//获取列表
			obj.language = rootState.language;
			obj.userToken = rootState.userToken;
            // console.log(rootState.userToken);
            // console.log(`${rootState.baseUrl}/assetUser/getUserList`);
			// rootGetters.post(`${rootState.baseUrl}/assetBorrow/getCompanyAssetBorrowList`,obj).then(res =>{
			rootGetters.post(`${rootState.baseUrl}/assetUser/getUserList`,obj).then(res =>{
				commit('SetList',res);
				// console.log("员工列表");
				// console.log(res.data);
			})
		},
        getAddStaff({rootGetters,commit,rootState},obj){	//添加
			obj.language = rootState.language;
			obj.userToken = rootState.userToken;
            console.log('添加用户');
            // console.log(rootState.userToken);
			rootGetters.post(`${rootState.baseUrl}/assetUser/addAssetUser`,obj).then(res =>{
				commit('SetAddStaff',res);
				// console.log("员工新增");
				// console.log(res);
			})
		},
        getDelStaff({rootGetters,commit,rootState},obj){	//删除
			obj.language = rootState.language;
			obj.userToken = rootState.userToken;
			rootGetters.post(`${rootState.baseUrl}/assetUser/deleteAssetUser`,obj).then(res =>{
				commit('SetDelStaff',res);
				// console.log("删除");
			})
		},
        getUpdateStaff({rootGetters,commit,rootState},obj){	//修改
			obj.language = rootState.language;
			obj.userToken = rootState.userToken;
			rootGetters.post(`${rootState.baseUrl}/assetUser/updateAssetUser`,obj).then(res =>{
				commit('SetUpdateStaff',res);
				// console.log("修改");
			})
		},
        getLogList({rootGetters,commit,rootState},obj){	//资产领用
			obj.language = rootState.language;
			obj.userToken = rootState.userToken;
            // console.log(rootState.userToken);

			rootGetters.post(`${rootState.baseUrl}/assetBorrow/getCompanyAssetBorrowList`,obj).then(res =>{
				commit('setLogList',res);
				// console.log("资产列表111");
			})
		},
        getUnbind({rootGetters,commit,rootState},obj){	//解绑
			obj.language = rootState.language;
			obj.userToken = rootState.userToken;
			rootGetters.post(`${rootState.baseUrl}/assetBorrow/forceUnbindMaterial`,obj).then(res =>{
				commit('setUnbind',res);
				// console.log("解绑");
				// console.log(res);
			})
		},


	}
}