import Url from "@/axios/url.js";
export default {
    namespaced: true,

    state:{
        dialogVisible:false, //显示详情列表
        tableData:[],  //财务报表列表
        tableDetail:[], //财务报表详情
        mold:'',
        id:'',
        companyList:[],
        title:'',
        loading:true,
    },
    mutations:{
        changeShow(state,res){
            state.dialogVisible = res
        },
        changeTableData(state,res){
            state.tableData = res
        },
        changeTableDetail(state,res){
            state.tableDetail = res
        },
        changeMold(state,res){
            state.mold = res
        },
        
        changeId(state,res){
            state.id = res
        },
        changeTitle(state,obj){
            state.title = obj
        },
        changeLoad(state,obj){
			state.loading = obj
		}
    },
    actions:{
        getFinancialList({ rootGetters, commit, rootState }, obj) {	// 获取财务报表列表
            commit('changeLoad', true)
            obj.userToken = rootState.userToken
            rootGetters.post(Url.getFinancialList, obj).then(res => {
                commit('changeTableData', res.data);
                commit('changeLoad', false)
            })
        },
        addFinancialDetail({ rootGetters, commit, rootState }, obj) {	// 财务报表添加
            return new Promise(resolve=>{
                obj.userToken = rootState.userToken;
                rootGetters.post(Url.addFinancialStatement, obj).then(res => {
                    resolve (res)
                })
            })
        },
        getFinancialDetail({ rootGetters, commit, rootState }, obj) {	// 获取财务报表详情
            return new Promise(resolve=>{
                obj.userToken = rootState.userToken;
                rootGetters.post(Url.getFinancialDetail, obj).then(res => {
                    commit('changeTableDetail', res.data);
                    resolve (res)
                })
            })
        },
        updateFinancialDetail({ rootGetters, commit, rootState }, obj) {	// 编辑财务报表
            return new Promise(resolve=>{
                obj.userToken = rootState.userToken;
                rootGetters.post(Url.updateDetail, obj).then(res => {
                    resolve (res)
                })
            })
        },
        deleteFinancialDetail({ rootGetters, commit, rootState }, obj) {	// 删除财务报表项
            return new Promise(resolve=>{
                obj.userToken = rootState.userToken;
                rootGetters.post(Url.deleteFinancialStatement, obj).then(res => {
                    resolve (res)
                    
                })
            })
        },
        
    }
}