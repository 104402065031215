const getLocalTime = function (str){	//获取本地时间格式
	let time = str?new Date(str):new Date();
	let y = time.getFullYear();
	let m = time.getMonth() + 1;
	let d = time.getDate();
	let H = time.getHours();
	let M = time.getMinutes();
	let S = time.getSeconds();
	let newStr = y +'-'+ (m>9?m:'0'+m) +'-'+ (d>9?d:'0'+d) +' '+ (H>9?H:'0'+H) +':'+ (M>9?M:'0'+M) +':'+ (S>9?S:'0'+S);
	return newStr;
}

const trim = function(str) { //去除两端空格
	let text = str.replace(/\ +/g,"");
	text = text.replace(/[\r\n]/g,"");
	return text;
}

export default{
	getLocalTime,
	trim,
}