import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import $http from '../axios/axiosHttp.js'

//首页和驾驶舱页
import HomeAndCockpit from './homeAndCockpit/homeAndCockpit.js'
//登录模块
import AboutLogin from './aboutLogin/aboutLogin.js'
//登录模块
import EquipmentData from './equipmentData/equipmentData.js'
//模板管理
import TempManage from './tempManage/tempManage.js'
//系统设置
import SystenManage from './systemManage/systemManage.js'
//操作记录查询
import RecordQuery from './recordQuery/recordQuery.js'

// 财务管理
import AddStandard from './addStandard/addStandard.js'
// 财务报表
import ReportForm from './reportForm/reportForm.js'
// 发票列表
import Invoice from './invoice/invoice.js'
//员工管理
import StaffManage from './staffManage/staffManage.js'

export default new Vuex.Store({
  state: {
	  	imgUrl:'https://codiatest.oss-cn-hangzhou.aliyuncs.com/codia/static',
		// baseUrl: 'http://10.43.10.81:3000/codia',  // 本地服务器
		baseUrl: 'https://www.offshorecloud.org.cn/codia',  // 测试服务器1
		// baseUrl: 'https://test.oneconn.com/codia',  // 测试服务器2
		// testUrl:'http://10.43.10.106:3000/codia',
		language: 'zh-cn', //中英文
		userToken: '',
		userInfo:{},	//用户信息
		enterpriseStatus:0,	//企业入网申请状态0(未激活)、1(正在审核中)、2(已激活)
		count:0,
		isLogin:false,
		activeIndex:'/IndexPage',	//显示的页面
		
  },
	getters: {
	  post: () => $http.post,
	  get: () => $http.get,
	  encrypt: () => $aes.encrypt,	//加密
	}, 
  mutations: {
		setActiveIndex(state, val){
			state.activeIndex = val;
		},
		setUserData(state, obj){
			// console.log("存储用户信息--");
			// console.log(obj);
			state.userToken = obj.token;
			state.userInfo = obj.userInfo;
			state.enterpriseStatus = obj.userInfo.userStatus;
			state.isLogin = true;
		},
		loginOutSys(state, obj){	//退出登录
			// console.log("退出登录");
			localStorage.removeItem('codiaLoginData');
			sessionStorage.removeItem('sessionUserData');
			state.userToken = '';
			state.userInfo = {};
			state.isLogin = false;
		},
		setEnterpriseStatus(state, val){
			state.enterpriseStatus = val;
		},
		
  },
  actions: {
		
  },
  modules: {
		HomeAndCockpit,
		AboutLogin,
		EquipmentData,
		TempManage,
		SystenManage,
		RecordQuery,
		AddStandard,
		ReportForm,
		Invoice,
		StaffManage
  }
})
