export default {
	namespaced:true,
	state:{
		count:0,
		userList:{},
		updateAddResult:{},
		deleteTemplateResult:{},
		baseTemplate:[],	//通用模板
		selectTemplateArr:[],	//选择的自定义模板
		apiExample:[],//获取api示例
	}, 
	mutations:{
		SetUserList(state, obj){
			state.userList = obj;
		},
		SetUpdateAddResult(state, obj){
			state.updateAddResult = obj;
		},
		SetDeleteTemplateResult(state, obj){
			state.deleteTemplateResult = obj;
		},
		SetBaseTemplate(state, obj){
			if(obj.data && obj.data.templateInfo){
				state.baseTemplate = obj.data.templateInfo;
			}else {
				state.baseTemplate = [];
			}
		},
		SetSelectTemplateArr(state, obj){
			if(obj.data && obj.data.templateInfo){
				state.selectTemplateArr = JSON.parse(obj.data.templateInfo);
				console.log('selectTemplateArr',state.selectTemplateArr)
			}else {
				state.selectTemplateArr = [];
			}
		},
		SetApiExample(state, obj){
			state.apiExample = obj;
		},
	},
	actions:{
		getList({rootGetters,commit,rootState},obj){	//获取列表
			obj.language = rootState.language;
			obj.userToken = rootState.userToken;
			rootGetters.post(`${rootState.baseUrl}/template/list`,obj).then(res =>{
				commit('SetUserList',res);
			})
		},
		uploadTemplate({rootGetters,commit,rootState},obj){	//新增
			obj.language = rootState.language;
			obj.userToken = rootState.userToken;
			rootGetters.post(`${rootState.baseUrl}/template/uploadTemplate`,obj).then(res =>{
				commit('SetUpdateAddResult',res);
			})
		},
		updateTemplate({rootGetters,commit,rootState},obj){	//修改
			obj.language = rootState.language;
			obj.userToken = rootState.userToken;
			rootGetters.post(`${rootState.baseUrl}/template/updateTemplate`,obj).then(res =>{
				// console.log(res);
				commit('SetUpdateAddResult',res);
			})
		},
		deleteTemplate({rootGetters,commit,rootState},obj){	//删除
			obj.language = rootState.language;
			obj.userToken = rootState.userToken;
			rootGetters.post(`${rootState.baseUrl}/template/deleteTemplate`,obj).then(res =>{
				commit('SetDeleteTemplateResult',res);
			})
		},
		getBaseTemplate({rootGetters,commit,rootState}){    //基础模板
			let obj = {};
			obj.language = rootState.language;
			obj.userToken = rootState.userToken;
			rootGetters.post(`${rootState.baseUrl}/template/getBaseFieldTemplate`,obj).then(res =>{
				console.log('========',res)
				commit('SetBaseTemplate',res);
			})
		},
		selectTemplate({rootGetters,commit,rootState}, obj){    //获取模板
			obj.language = rootState.language;
			obj.userToken = rootState.userToken;
			rootGetters.post(`${rootState.baseUrl}/template/selectTemplate`,obj).then(res =>{
				commit('SetSelectTemplateArr',res);
			})
		},
		GetApiExample({rootGetters,commit,rootState}, obj){    //获取api示例
			obj.language = rootState.language;
			obj.userToken = rootState.userToken;
			rootGetters.post(`${rootState.baseUrl}/api/getTemplateFormat`,obj).then(res =>{
				commit('SetApiExample',res);
			})
		},
		
	}
}