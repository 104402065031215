<template>
  <div class="home_page_box">
    <div class="loading_box" v-if="pagesLoading"></div>
    <div class="header">
      <Header></Header>
    </div>
    <div class="home_content_box">
      <router-view />
      <!-- 可以不传值  -->
      <set-top :getText="'返回顶部'" :getImg="setTopImg" id="set-top"></set-top>
    </div>
    <div class="footer">
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
import { Loading } from "element-ui";
import { mapActions, mapMutations, mapState } from "vuex";
import Header from "./unLoginPages/components/header.vue";
import Footer from "./unLoginPages/components/footer.vue";
import SetTop from "./unLoginPages/components/toTop.vue";
export default {
  components: {
    Header,
    Footer,
    SetTop,
  },
  data() {
    return {
      pagesLoading: false,
      // setTopImg: require('@/img/***/setTop.png')
      setTopImg: require("@/assets/toTopImg/toTop.png"),
      scroll: "",
    };
  },
  watch: {
    isLogin() {
      if (this.isLogin) {
        this.$router.replace("/homePage");
      }
    },
  },
  computed: {
    ...mapState({
      isLogin: (state) => state.isLogin,
      activeIndex: (state) => state.activeIndex,
    }),
  },
  methods: {
    ...mapActions({
      login: "login",
    }),

    handleScroll() {
      this.scroll =
        document.documentElement.scrollTop || document.body.scrollTop;
      // console.log(this.scroll)
      if (this.scroll >= 400) {
        document.querySelector("#set-top").style.display = "block";
      } else {
        document.querySelector("#set-top").style.display = "none";
      }
    },
  },
  mounted() {
    //自动登录
    let userData = localStorage.getItem("codiaLoginData");
    let sessionUserData = sessionStorage.getItem("sessionUserData");
    if (userData || sessionUserData) {
      //开起白屏
      this.pagesLoading = true;
      setTimeout(() => {
        this.pagesLoading = false;
      }, 200);
    } else {
      if (!this.isLogin) {
        this.$router.replace(this.activeIndex);
      } else {
        this.$router.replace("/homePage");
      }
    }

    window.addEventListener("scroll", this.handleScroll);
  },

  //销毁,否则跳到别的路由还是会出现
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>
<style lang="scss" scoped="scoped">
#set-top {
  display: none;
}
// #set-top:hover {
//   cursor: pointer;
// }

.home_page_box {
  width: 100%;
  min-height: 100%;
  .loading_box {
    width: 100%;
    height: 100%;
    background-color: #fff;
    position: absolute;
    z-index: 999;
    top: 0;
    left: 0;
  }
  .header {
    width: 100%;
    height: 60px;
  }
  .home_content_box {
    width: 100%;
    min-height: calc(100vh - 320px);
  }
  .footer {
    width: 100%;
    height: 260px;
    box-sizing: border-box;
  }
}
</style>
