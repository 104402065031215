import Url from "@/axios/url.js";
export default {
    namespaced: true,

    state: {
        dialogFormVisible: false, //将编辑表单显示或隐藏
        mold: '',   //各个编辑状态的标识
        tableUp:false,
        formDataEdit: {}, // 编辑状态时表单对应的数据
        tableData:[],
        CompanyList: [], // 公司名列表
        tableDataCustom:[],//收费自定义列表
        CostList:[], //费用类型列表
        title:'',
        loading:true,
    },

    mutations: {
        // 改变表单的显示隐藏状态
        changeShow(state, obj) {
            state.dialogFormVisible = obj
        },
        // 表单的类型说明
        changeType(state, obj) {
            state.mold = obj
        },
        // 编辑状态时表单对应的数据
        changeFormEdit(state, obj) {
            state.formDataEdit = obj
        },
        // 获取收费标准列表
        changeList(state,obj){
            state.tableData = obj
        },
        // 获取收费自定义列表
        changeListCustom(state,obj){
            state.tableDataCustom = obj
        },
        // 获取公司名列表
        changeCompanyList(state, obj) {
            state.CompanyList = obj
        },
        // 获取所有费用类型
        changeCostList(state, obj) {
            state.CostList = obj
        },
        changeState(state,obj){
            state.tableUp = obj
        },
        changeTitle(state,obj){
            state.title = obj
        },
        changeLoad(state,obj){
			state.loading = obj
		}
    },

    actions: {
        async getList({ rootGetters, commit, rootState }, obj) {	// 获取收费标准列表
            commit('changeLoad', true)
            obj.userToken = rootState.userToken,
            obj.language = rootState.language
            rootGetters.post(Url.list, obj).then(res => {
                commit('changeList', res.data)
                commit('changeLoad', false)
            })
            
        },
        getListCustom({ rootGetters, commit, rootState }, obj) {	// 获取收费自定义列表
            commit('changeLoad', true)
            obj.userToken = rootState.userToken,
            obj.language = rootState.language,
            rootGetters.post(Url.list, obj).then(res => {
                commit('changeListCustom', res.data);
                commit('changeLoad', false)
            })
        },
        addList({ rootGetters, commit, rootState }, obj) {	// 收费标准新增事件
            obj.userToken = rootState.userToken,
            obj.language = rootState.language
            return new Promise(resolve=>{
                rootGetters.post(Url.add, obj).then(res => {
                        resolve (res.errorCode)
                })
            })
            
        },
        deleteList({ rootGetters, commit, rootState }, obj) {	// 收费标准删除事件
            obj.userToken = rootState.userToken,
            obj.language = rootState.language
            return new Promise(resolve=>{
                rootGetters.post(Url.delete, obj).then(res => {
                    resolve (res.errorCode)
                })
            }) 
        },
        updateList({ rootGetters, commit, rootState }, obj) {	// 收费标准修改事件
            obj.userToken = rootState.userToken,
            obj.language = rootState.language
            return new Promise(resolve=>{
                rootGetters.post(Url.update, obj).then(res => {
                    if (res.errorCode == 200) {
                        resolve (res.errorCode)
                    }
                })
            })
            
        },
        getCompanyList({ rootGetters, commit, rootState }) {	// 获取公司名列表
            const obj = {
                userToken: rootState.userToken,
                language: rootState.language,
            };
            rootGetters.post(Url.getCompanyList, obj).then(res => {
                commit('changeCompanyList', res.data);
            })
        },
        getCostList({ rootGetters, commit, rootState }) {	// 获取费用类型列表
            const obj = {
                userToken: rootState.userToken,
                language: rootState.language,
            };
            rootGetters.post(Url.getCostList, obj).then(res => {
                commit('changeCostList', res.data);
            })
        },
    }
}