<template>
	<div class="footer_pages_box">
		<div class="footer_cont_box">
			<div class="left_box">
				<div class="item_box">
					<img class="logo_img" src="@/assets/unLoginPages/components/logp_bottom.png" />
					<div class="center_box">
						<el-menu
							:default-active="activeIndex"
							class="el-menu-demo"
							mode="horizontal"
							@select="handleSelect"
							background-color="#191919"
							text-color="#fff"
							active-text-color="#6DB1EA"
						>
							<el-menu-item index="/IndexPage">首页</el-menu-item>
							<el-menu-item index="/equipmentQuery">海工装备查询</el-menu-item>
							<el-menu-item index="/CreatedQRcode">海工装备二维码</el-menu-item>
							<el-menu-item index="/openInterface">开放接口</el-menu-item>
							<el-menu-item index="/aboutUs">关于我们</el-menu-item>
						</el-menu>
					</div>
				</div>
				<div class="company_mesg">
					<span>中国深远海海洋工程装备技术产业联盟</span>
					<span class="name_box">李键(15062582879)，程晓晖(13862488911) (9:00 - 18:00)</span>
				</div>
			</div>
				<img class="img_box" :src="imgUrl+'/unLoginPages/components/erweima.png'"  />

		</div>
		<div class="copyright_box">
			版权所有 沪ICP备20006997号
		</div>
	</div>
</template>

<script>
	import {mapActions, mapMutations, mapState} from 'vuex'
	export default {
		data(){
			return {
				
			}
		},
		computed:{
			...mapState({
				activeIndex: state => state.activeIndex,
				imgUrl: state => state.imgUrl,
			}),
		},
		methods:{
			...mapMutations({
				setActiveIndex:'setActiveIndex',
			}),
			handleSelect(key, keyPath) {
				this.setActiveIndex(key);
				this.$router.replace(key);
			}
		},
		mounted() {
		function change() {
		var Root = document.documentElement;
		Root.style.fontSize = window.innerWidth / 192 + 'px';
		// console.log(Root.style.fontSize);
		};
		change();
		window.onresize = function () {

		// console.log('浏览器窗口的大小被改变了');
		change();
    }
		}
	}
</script>

<style lang="scss" scoped>
	.footer_pages_box{
		width: 100%;
		height: 100%;
		position: relative;
		background-color: #191919;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #fff;
		position: relative;
		.footer_cont_box{
			width: $heightBaseContent;
			height: 100%;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			.left_box{
				width: calc(100% - 160px);
				height: 100%;
				.item_box{
					width: 100%;
					height: 40%;
					box-sizing: border-box;
					border-bottom: 1px solid #666;
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: space-between;
					.logo_img{
						width: 248px;
					}
					.center_box{
						width: calc(100% - 248px);
						height: 100%;
						display: flex;
						align-items: center;
						justify-content: center;
					}
				}
				.company_mesg{
					width: 100%;
					height: calc(60% - 30px);
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					justify-content: center;
					line-height: 26px;
					.name_box{
						color: #999;
						font-size: $mini-font-size;
					}
				}
			}
			.img_box{
				width: 130px;
			}
		}
		.copyright_box{
			width: 100%;
			height: 34px;
			display: flex;
			justify-content: center;
			position: absolute;
			z-index: 2;
			bottom: 0;
			left: 0;
			color: #999;
			font-size: $mini-font-size;
			cursor: pointer;
		}
	}
</style>

<style scoped>
.el-menu-item {
  font-size: 1.6rem !important;
}
.el-menu-item {
  padding:0 2rem !important;
}
</style>
